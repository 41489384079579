import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import MetaTags from 'react-meta-tags';


const TITLE = 'Trust Global'
const Home = () => {

  return (
    <>
    	<MetaTags>
            <title>{TITLE}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content={TITLE} />
            <meta name="description" content="Our Job is to find and bring anything you want from anywhere with seamless sourcing all around the world,Email:info@trustglobal.com.bd"/>
            <meta name="keywords" content="The Trust Global, Trust Global, Import, Bandladesh, BD, trustglobal.com.bd, Sourcing, Export, trustglobal, bd, Best, Importer, Dhaka"/>
            <meta name="author" content="Mahir Tanjim"/>
          </MetaTags>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />

     
    </>
  );
}

export default Home;