import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Typist from 'react-typist';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {


  

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  //for loop of typist
  const [count, setCount] = useState(1);
  useEffect(() => {
    // document.title = `You clicked ${count} times`;
    console.log("Count: " + count);
    setCount(1);
  }, [count]);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Inovation in the sector of
              {count ? (
              <Typist onTypingDone={() => setCount(0)}>
              <span style={{color: '#ff5900'}}> Import</span>
                <Typist.Backspace count={6} delay={500} />
                <span style={{color: '#109c76'}}> Sourcing</span>
                <Typist.Backspace count={8} delay={500} />
                <span style={{color: '#EA4335'}}>Logistics</span>
                <Typist.Backspace count={9} delay={500} />
                <span style={{color: '#4285F4'}}> Supply Chain</span>
                <Typist.Backspace count={11} delay={1000} />
              </Typist>
              ) : (
                ""
              )}

            </h1>
            
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Our Job is to find and bring anything you want from anywhere, we give the best of ours to our coustomers
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
               
                  
                  <Button tag="a" style={{backgroundColor: '#109c76',color: 'white'}} wideMobile href="mailto:info@trustglobal.com.bd">
                    Write a Mail
                    </Button>
                    
                    <Button tag="a" style={{backgroundColor: '#ff5900',color: 'white'}} wideMobile href="https://cbm.trustglobal.com.bd/">
                    CBM Calculator
                    </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div>
          <div>
                     <h2 style={{color: '#ff5900'}}>Get In Touch</h2>
                     <p>Email:info@trustglobal.com.bd<br/>

                        Phone:+8801938775447</p>
         </div>
         </div>  

          
        </div>

      </div>
         
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;