import React from 'react';
// import sections
import Heroc from '../components/sections/c1';

import MetaTags from 'react-meta-tags';


const TITLE = 'Trust Global'
  
    	
const Contact = () => {

  return (
    <>
    <MetaTags>
            <title>{TITLE}</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content={TITLE} />
            <meta name="description" content="Our Job is to find and bring anything you want from anywhere with seamless sourcing all around the world,Email:info@trustglobal.com.bd"/>
            <meta name="keywords" content="Contact, Trust Global, Import, Bandladesh, BD, trustglobal.com.bd, Sourcing, Export, trustglobal, .bd, Best, Importer, Dhaka"/>
            <meta name="author" content="Mahir Tanjim"/>
          </MetaTags>
      <Heroc className="illustration-section-01" />
     
    </>
  );
}

export default Contact;
