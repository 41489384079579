import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';



const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
    
      <div className="m-0">
        <Link to="/">
          <center>
          <Image
            src={require('./../../../assets/images/logo.png')}
            alt="Open"
            width={32}
            height={32} />
            </center>
            <center><b>
       <span className="container-sm"> <span style={{color: '#109c76'}}>Trust </span> <span style={{color: '#ff5900'}}>Global</span>
        </span></b>
        </center>
        </Link>
     
      </div>
    </div>
  );
}

export default Logo;